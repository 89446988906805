import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';
import checkAccess from './checkAccess';

const {
  REACT_APP_OIDC_CLIENT_ID: clientId,
  REACT_APP_OIDC_AUTHORITY: authority,
} = process.env;

const location = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

const webStorageStub = {
  async set() { return null; },
  async get() { return null; },
  async remove() { return null; },
  async getAllKeys() { return []; },
};

const userManager = createUserManager({
  // Who to contact, and how to identify ourselves
  authority,
  client_id: clientId,
  // What access we are requesting
  scope: 'openid profile',
  // Where (uris) and how the OIDC server should respond
  redirect_uri: `${location}/callback/login`,
  post_logout_redirect_uri: `${location}/callback/logout`,
  silent_redirect_uri: `${location}/callback/renew`, // since this is part of the main app bundle (due to using CRA), renews will cause the browser to re-download the whole bundle
  response_type: 'code',
  // What we should do with the response
  loadUserInfo: true,
  userStore: checkAccess() ? new WebStorageStateStore({ store: window.localStorage }) : webStorageStub,
  stateStore: checkAccess() ? new WebStorageStateStore({ store: window.localStorage }) : webStorageStub,
  // If and how long in advance to expiration we should refresh to token
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 60,
  // If and how we should validate the session inbetween access tokens
  monitorSession: false,
  stopCheckSessionOnError: false,
  monitorAnonymousSession: false,
});

export default userManager;
