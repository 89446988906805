import styled from 'styled-components';
import { SPACE } from 'ui-component-library/spacing';

export const TopNavContainer = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: none;
  top: 0;
  left: 0;
  height: 64px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const LeftNavDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RightNavDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const Logo = styled.img`
  height: 24px;
  width: 232px;
  margin-left: ${SPACE.S20};
`;

export const LogoIcon = styled.img`
  // HACK: Explicitly defining height adds unwanted border around svg
  // height: 60px; 
  width: 64px;
  box-shadow: 0px 3px 6px #0000001A;
`;
