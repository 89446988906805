import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner, Modal } from 'ui-component-library';
import { useHistory } from 'react-router-dom';
import {
  CardBlock, SearchResultHeader, SpinnerBlock, ModalTitle,
} from './search-popup.style';
import { TableItem, TableRow, RowInfo } from './table/table.styles';
import Table from './table/table';
import { resetState as resetGlobalSearchState } from '../../store/global-search/slice';

const columnsSettings = [
  { title: 'BOL#' },
  { title: 'Mode' },
  { title: 'PRO #', width: 150 },
  { title: 'PO #', width: 200 },
  { title: 'REF #', width: 200 },
  { title: 'Customer Company Name', width: 200 },
  { title: 'Customer BOL', width: 150 },
  { title: 'Sales Rep Name', width: 200 },
  { title: 'Booked By', width: 150 },
  { title: 'Pickup Date', width: 100 },
  { title: 'Delivery Date', width: 100 },
  { title: 'Shipper Company Name', width: 200 },
  { title: 'Shipper City', width: 150 },
  { title: 'Shipper State', width: 150 },
  { title: 'Shipper Zip', width: 150 },
  { title: 'Consignee Company Name', width: 200 },
  { title: 'Consignee City', width: 150 },
  { title: 'Consignee State', width: 150 },
  { title: 'Consignee Zip', width: 150 },
  { title: 'Carrier', width: 200 },
  { title: 'Equipment Type', width: 100 },
  { title: 'Total Revenue', width: 100 },
  { title: 'Total Cost', width: 100 },
];

const numberOfDecimalPlaces = 2;

const idStory = 'SLD-521';

const SearchPopup = () => {
  const data = useSelector((state) => state.globalSearch.orders);
  const loader = useSelector((state) => state.globalSearch.loading);
  const searchQuery = useSelector((state) => state.globalSearch.searchQuery);
  const history = useHistory();
  const dispatch = useDispatch();

  const closeHandler = () => {
    dispatch(resetGlobalSearchState());
  };

  const redirectToOrder = (id, shipmentModeType) => {
    closeHandler();
    history.push(`${shipmentModeType === 'tl' ? '/tl' : ''}/sales-order/${id}`);
  };

  const openOrderInNewTab = (id, shipmentModeType) => {
    window.open(`${shipmentModeType === 'tl' ? '/tl' : ''}/sales-order/${id}`, '_blank');
  };

  const setTruckEngineRows = () => !!data.length
    && data.map((item) => {
      const truckEngineData = [
        item.bolNumber,
        item.shipmentMode,
        item.proNumber,
        item.poNumber,
        item.refNumber,
        item.accountName,
        item.customerBol,
        item.salesRepName,
        item.bookedBy,
        item.pickupDate,
        item.deliveryDate,
        item.shipperCompanyName,
        item.shipperCity,
        item.shipperState,
        item.shipperZipCode,
        item.consigneeCompanyName,
        item.consigneeCity,
        item.consigneeState,
        item.consigneeZipCode,
        item.carrier,
        item.equipmentType,
        Number(item.revenue).toFixed(numberOfDecimalPlaces),
        Number(item.cost).toFixed(numberOfDecimalPlaces),
      ];
      const shipmentModeType = item.shipmentMode.toLowerCase();

      return (
        <TableRow id={`${idStory}_row_${item.bolNumber}`}>
          <RowInfo>
            {columnsSettings.map((column, index) => {
              const cellValue = truckEngineData[index] ? truckEngineData[index] : '';
              return (
                <TableItem
                  id={`${idStory}_row_${item.bolNumber}_${column.title}`}
                  highlight={searchQuery.toLowerCase().split(/,\s*/).some((value) => cellValue.toLowerCase().includes(value))}
                  width={column.width}
                  onClick={
                    column.title === 'BOL#'
                      ? () => {
                        redirectToOrder(truckEngineData[index], shipmentModeType);
                      }
                      : null
                  }
                  onContextMenu={
                    column.title === 'BOL#'
                      ? (event) => {
                        event.preventDefault();
                        openOrderInNewTab(truckEngineData[index], shipmentModeType);
                      }
                      : null
                  }
                >
                  {truckEngineData[index]}
                </TableItem>
              );
            })}
          </RowInfo>
        </TableRow>
      );
    });

  return (
    <Modal
      title={<ModalTitle id={`${idStory}_modal_title`}>Search Results</ModalTitle>}
      isOpen
      onModalClose={closeHandler}
      removePadding
      removeMarginBottom
      id={`${idStory}_modal`}
    >
      <CardBlock>
        {loader ? (
          <SpinnerBlock>
            <Spinner />
          </SpinnerBlock>
        ) : (
          <>
            <SearchResultHeader id={`${idStory}_resultLabel`}>
              {`"${searchQuery}" provided ${data.length} results.`}
            </SearchResultHeader>
            <Table id={`${idStory}_table`} columns={columnsSettings} setRows={setTruckEngineRows} rowsData={data} />
          </>
        )}
      </CardBlock>
    </Modal>
  );
};

export default SearchPopup;
