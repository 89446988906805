import api from './api';

const orderURL = process.env.REACT_APP_ORDER_API_URL;

const globalSearchUrl = `${orderURL}/GetOrderBoardGlobalSearch`;

export default async function fetchGlobalSearchResults(apiToken, payload) {
  const options = {
    authToken: apiToken,
    url: globalSearchUrl,
    data: {
      searchText: payload,
    },
  };

  return api.post(options);
}
