import { useEffect } from 'react';
import logger from '../../../utils/logger';

let channel;
let connectTimes = 0;
const maxRetryConnection = 10;
const retryInterval = 3 * 1000;
let timeoutHandler;

const postMessage = async (message) => {
  const registration = await navigator?.serviceWorker?.ready;
  registration.active.postMessage(message);
};

const ServiceWorkerClient = ({ onNewVersionDetected }) => {
  useEffect(() => {
    async function createChannel() {
      if (channel) {
        logger.debug('channel has already been initialized, skip!');
        return undefined;
      }

      channel = new BroadcastChannel('sw-messages');
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }

      const messageHandlers = {
        YES_I_AM_READY: () => { postMessage({ type: 'CHECK_FOR_UPDATES' }); messageHandlers.YES_I_AM_READY = () => {}; },
        NEW_VERSION_DETECTED: () => { onNewVersionDetected(); },
      };

      const onMessage = (message) => {
        logger.debug(`onMessage ${message.data.type}`);

        messageHandlers?.[message?.data?.type]?.();
      };

      channel.addEventListener('message', onMessage);

      postMessage({ type: 'ARE_YOU_READY' });

      return () => {
        if (timeoutHandler) {
          clearTimeout(timeoutHandler);
        }
        postMessage({ type: 'GOOD_BYE' });
        channel.removeEventListener('message', onMessage);
      };
    }

    if (!navigator?.serviceWorker?.controller || navigator?.serviceWorker?.controller.state !== 'activated') {
      if (connectTimes < maxRetryConnection) {
        connectTimes += 1;
        if (timeoutHandler) {
          clearTimeout(timeoutHandler);
        }
        logger.debug(`navigator.serviceWorker.controller is not available, schedule to connect it again in ${retryInterval}`);
        timeoutHandler = setTimeout(createChannel, retryInterval);
      }
      return undefined;
    }

    return createChannel();
  }, [onNewVersionDetected]);

  return null;
};

export default ServiceWorkerClient;
