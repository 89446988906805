import { createSlice, createAction } from '@reduxjs/toolkit';
import {
  USER_FOUND, reducer as upstreamReducer,
} from 'redux-oidc';
import userManager from '../../utils/userManager';

const initialState = {
  user: null,
  isLoadingUser: null,
};

// Create an action with the exact same name as the redux-oidc exported action
const userFoundOverride = createAction(USER_FOUND);

const oidcSlice = createSlice({
  initialState,
  name: 'redux-oidc', // NOTE: value doesnt actually matter, since we export no actions of our own
  reducers: {},
  // Using builder syntax for programatic slice creation, see https://redux-toolkit.js.org/api/createSlice#the-extrareducers-builder-callback-notation
  extraReducers: (builder) => (
    builder
      .addCase(userFoundOverride, (state, action) => {
        const upstreamState = upstreamReducer(state, action);
        // Casing determined by the library
        // eslint-disable-next-line camelcase
        if (upstreamState.user?.expires_in <= userManager.settings.accessTokenExpiringNotificationTime) {
          return state;
        }

        return {
          ...upstreamState,
          user: {
            ...upstreamState.user,
            // Some shortcuts for poorly named stuff in our token
            username: upstreamState.user?.profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
            emailaddress: upstreamState.user?.profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
            // eslint-disable-next-line camelcase
            accessToken: upstreamState.user?.access_token,
          },
        };
      })
      // Pass all other actions straight to the original redux-oidc reducer
      .addDefaultCase(upstreamReducer)
  ),
});

export default oidcSlice.reducer;
