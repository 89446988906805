import React from 'react';
import {
  Switch, Route, Redirect, useRouteMatch,
} from 'react-router-dom';

import Login from './login';
import Logout from './logout';

const UserManagement = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/login`} component={Login} />
      <Route path={`${match.path}/logout`} component={Logout} />
      <Redirect from="*" to="/error/404" />
    </Switch>
  );
};

export default UserManagement;
