import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { loadUser, OidcProvider } from 'redux-oidc';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'ui-components/theme';

import GlobalStyle from './index.style';
import App from './components/app/app';

import store from './store';
import { logger, userManager } from './utils';
import { register as registerServiceWorker } from './serviceWorkerBootstrap';

loadUser(store, userManager); // Attempt user load from session storage in case we are already authenticated

const { REACT_APP_THIRD_PARTY_LIBS } = process.env;
if (REACT_APP_THIRD_PARTY_LIBS) {
  const libs = JSON.parse(REACT_APP_THIRD_PARTY_LIBS);
  libs.forEach((params) => {
    const {
      src, async, type, crossOrigin,
    } = params;
    const script = document.createElement('script');
    script.src = src;
    script.async = async ?? true;
    script.type = type ?? 'text/javascript';
    script.crossOrigin = crossOrigin;
    if (crossOrigin) {
      script.onerror = (errorEvent) => logger.warn('Third party script error', { params, errorEvent });
    } else {
      script.onerror = () => logger.warn('Failed to download third party script', { params });
    }
    document.head.appendChild(script);
  });
}

ReactDOM.render(
  <Provider store={store}>
    <OidcProvider userManager={userManager} store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </OidcProvider>
  </Provider>,
  document.getElementById('root'),
);

if ((process.env.NODE_ENV === 'production' || process.env.REACT_APP_SW_DEVELOP_MODE === 'true') && 'serviceWorker' in navigator) {
  registerServiceWorker();
}
