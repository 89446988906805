import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  useHistory, useLocation, useRouteMatch,
} from 'react-router-dom';

import {
  LeftBarNav, StyledLink, StyledModal, Title,
} from './side-nav.style';
import NavButton from '../../shared-components/nav-button';
import Alert from './alert';
import IconLink from './icon-link';
import { getFromEnv } from '../../utils';

const trackAndTraceUrl = getFromEnv('REACT_APP_TRACK_AND_TRACE_URL');
const powerBiUrls = JSON.parse(getFromEnv('REACT_APP_POWER_BI_URLS'));
const environment = getFromEnv('REACT_APP_ENV');
// const isStage = window.location.hostname === 'gtz-wus-tms-base-web-sg.azurewebsites.net'; COMMENTED FOR FUTURE USE

const powerBiLinks = [
  {
    id: 1,
    title: 'AR Dashboard',
    url: powerBiUrls.arDashboard,
  },
  {
    id: 2,
    title: 'OPS Dashboard',
    url: powerBiUrls.opsDashboard,
  },
  {
    id: 3,
    title: 'Rebill Reason Report',
    url: powerBiUrls.rebillReasonReport,
  },
  {
    id: 4,
    title: 'Shipment Manifest Report',
    url: powerBiUrls.shipmentManifestReport,
  },
  {
    id: 5,
    title: 'Customer Retention Report',
    url: powerBiUrls.customerRetentionReport,
  },
  {
    id: 6,
    title: 'Real Time Dashboard',
    url: powerBiUrls.realTimeDashboard,
  },
  {
    id: 7,
    title: 'Quotes Not Booked Report',
    url: powerBiUrls.quotesNotBookedReport,
  },
];

const baseId = 'SLD-223';

const SideNav = () => {
  const history = useHistory();
  const location = useLocation();
  const isTlSalesOrder = useRouteMatch('/tl/sales-order');
  const isSalesOrderBoard = useRouteMatch('/sales-order-board');
  const trackAndTraceVisible = useSelector((state) => environment === 'development' && state.access.permissions.includes('trackAndTrace'));
  const userManagementVisible = useSelector((state) => state.access.permissions.includes('userManagement'));

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isBiModalOpen, setIsBiModalOpen] = useState(false);
  const [next, setNext] = useState();
  const [lastBoard, setLastBoard] = useState();

  const handleNav = (e, destination) => {
    if (
      location.pathname === (destination.pathname ?? destination)
        || location.pathname.includes(destination.pathname ?? destination)
    ) {
      e.preventDefault();
      return;
    }

    if (
      (location.pathname === '/create-quote' || location.pathname === '/create-quote-expedite')
      && location.pathname !== (destination.pathname ?? destination)
      && !(destination.pathname ?? destination).startsWith(trackAndTraceUrl)
      && sessionStorage.getItem('isInProcessOfCreating')
    ) {
      e.preventDefault();
      setNext(destination);
      setIsAlertOpen(true);
    }
    // Set lastboard when leaving
    if (
      isSalesOrderBoard
      && !(destination.pathname ?? destination).startsWith('/sales-order-board')
    ) {
      setLastBoard(location);
    }
  };
  const handleModalClose = () => {
    setIsAlertOpen(false);
  };
  const handleModalConfirm = () => {
    sessionStorage.removeItem('isInProcessOfCreating');
    setIsAlertOpen(false);
    history.push(next);
  };
  const handleCloseBiModal = () => {
    setIsBiModalOpen(false);
  };

  const handleOpenLink = (link) => {
    window.open(link, '_blank');
    setIsBiModalOpen(false);
  };

  useEffect(() => {
    if (isSalesOrderBoard && lastBoard) {
      setLastBoard(null);
    }
  }, [isSalesOrderBoard, lastBoard]);

  return (
    <>
      <LeftBarNav>
        <IconLink
          to={lastBoard || {
            pathname: `/sales-order-board/${isTlSalesOrder ? 'tl' : 'ltl'}`,
            search: location.state?.orderBoardReferrer?.query,
            state: location.state?.orderBoardReferrer,
          }}
          title="Order Management"
          icon="OrderMGMT"
          text="Orders"
          onClick={handleNav}
          isActive={(match) => {
            if (match) return match;
            return !!isSalesOrderBoard;
          }}
          tabIndex={-1}
          id={`${baseId}-freight-link`}
        />
        <IconLink
          to="/create-quote"
          title="Create Quote"
          text="Create"
          icon="Create"
          onClick={handleNav}
          tabIndex={-1}
          id={`${baseId}-quote-link`}
        />
        <IconLink
          to="/create-quote-expedite"
          title="Expedite"
          text="Expedite"
          icon="Expedite"
          onClick={handleNav}
          tabIndex={-1}
          id={`${baseId}-quote-expedite-link`}
        />
        <IconLink
          to="/sales-order-board-expedite"
          title="Expedite Orders"
          text="EXP Orders"
          icon="ExpediteBoard"
          onClick={handleNav}
          tabIndex={-1}
          id={`${baseId}-sales-order-board-expedite-link`}
        />
        <IconLink
          to="/customer-management/customers"
          title="Customer Management"
          text="Customer"
          icon="CustomerMGMT"
          onClick={handleNav}
          tabIndex={-1}
          id={`${baseId}-customer-mgmt-link`}
        />
        {userManagementVisible && (
          <IconLink
            to="/user-management/boards"
            title="User Management"
            text="User MGMT"
            icon="UserGroup"
            onClick={handleNav}
            tabIndex={-1}
            id={`${baseId}-user-mgmt-link`}
          />
        )}
        <IconLink
          to="/vendor-management"
          title="Vendor Management"
          text="Vendor"
          icon="VendorMGMT"
          onClick={handleNav}
          tabIndex={-1}
          id={`${baseId}-carrier-mgmt-link`}
        />
        <IconLink
          to="/rate-admin"
          title="Rate Admin"
          text="Rate Admin"
          icon="RateAdmin"
          onClick={handleNav}
          tabIndex={-1}
          id={`${baseId}-rate-admin-link`}
        />
        {trackAndTraceVisible && (
          <IconLink
            to={{ pathname: trackAndTraceUrl }}
            title="Track and Trace"
            icon="TrackandTrace"
            text="Track & Trace"
            target="_blank"
            onClick={handleNav}
            tabIndex={-1}
            id={`${baseId}-trank-trace-link`}
          />
        )}
        <NavButton
          title="PowerBI"
          text="Power BI"
          icon="PowerBI"
          onClick={() => { setIsBiModalOpen(true); }}
          tabIndex={-1}
          id={`${baseId}-powerBi-link`}
        />
      </LeftBarNav>
      {isAlertOpen ? (
        <Alert
          isOpen={isAlertOpen}
          onModalClose={handleModalClose}
          onModalConfirm={handleModalConfirm}
          title="Alert"
          id={`${baseId}-abandon-quote-create-confirm`}
        />
      ) : null}
      {isBiModalOpen && (
        <StyledModal
          id="navbar_TL-12089_powerBIModal"
          title="Navigate to Power BI"
          isOpen={isBiModalOpen}
          onClose={handleCloseBiModal}
          buttonConfig={[
            {
              id: 'navbar_SLD-14589_powerBIModal_opsDashButton',
              text: 'Cancel',
              secondary: true,
              onClick: handleCloseBiModal,
            },
          ]}
        >
          <Title>Where would you like to navigate to in Power BI?</Title>
          {powerBiLinks.map((link) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div key={link.id} onClick={() => handleOpenLink(link.url)}>
              <StyledLink type="body2">{link.title}</StyledLink>
            </div>
          ))}
        </StyledModal>
      )}
    </>
  );
};

export default SideNav;
