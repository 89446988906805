import React from 'react';
import PropTypes from 'prop-types';
import { IconFont } from 'ui-components';

import { StyledNavButton, StyledText } from './nav-button.style';

const NavButton = ({
  onClick,
  icon,
  text,
  title,
  tabIndex,
  isActive,
  id,
}) => (
  <StyledNavButton
    title={title}
    onClick={onClick}
    tabIndex={tabIndex}
    isActive={isActive}
    id={id}
  >
    <IconFont className="nav-button-icon" icon={icon} id={`${id}-icon`} size="24px" />
    {text && <StyledText className="nav-button-text">{text}</StyledText>}
  </StyledNavButton>
);
NavButton.propTypes = {
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  tabIndex: PropTypes.number,
  text: PropTypes.string,
  title: PropTypes.string,
};

NavButton.defaultProps = {
  icon: undefined,
  isActive: false,
  tabIndex: undefined,
  text: undefined,
  title: undefined,
};

export default NavButton;
