import fetchGlobalSearchResults from '../../utils/api/global-search';
import {
  resetState,
  searchFailed,
  setResultOfGlobalSearch,
  startSearching,
} from './slice';

export default function getResultOfGlobalSearch(searchText) {
  return async (dispatch, getState) => {
    try {
      dispatch(startSearching(searchText));
      const state = getState();
      const apiToken = state.oidc.user ? state.oidc.user.access_token : '';
      const searchResult = await fetchGlobalSearchResults(apiToken, searchText);
      dispatch(setResultOfGlobalSearch(searchResult.data.model));
    } catch (error) {
      dispatch(resetState());
      dispatch(searchFailed({
        status: 'failed',
        message: error,
      }));
      throw new Error('Failed to perform the global search', {
        error,
      });
    }
  };
}
