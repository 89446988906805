import React from 'react';
import PropTypes from 'prop-types';

import {
  TableBody, TableHeader, TableItem, TableWrapper, TableRow, Root,
} from './table.styles';

const Table = ({
  id, columns, setRows, rowsData,
}) => (rowsData?.length ? (
  <Root id={id}>
    <TableWrapper>
      <TableHeader id={`${id}_header`}>
        <TableRow>
          {columns.map((column) => (
            <TableItem id={`${id}_header_${column.title}`} width={column.width} key={column.title}>
              {column.title}
            </TableItem>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody id={`${id}_rows`}>
        {setRows()}
      </TableBody>
    </TableWrapper>
  </Root>
) : null);

Table.propTypes = {
  id: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      grow: PropTypes.number,
    }),
  ).isRequired,
  setRows: PropTypes.func.isRequired,
  rowsData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

export default Table;
