import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Button, Modal } from 'ui-component-library';

import { getFromEnv } from '../../utils';

import AccessPrompt from './access-prompt';
import {
  Wrapper, ErrorHead, ErrorText, TeamContainer, MemberContainer, MemberPortrait, MemberName, ContentContainer,
} from './error-page.style';
import fallbackPortrait from './portraits/fallback.jpg';

const errorStrings = {
  'fail-to-mount': {
    explination: 'sub-application failed to load',
    resolution: [
      'This can sometimes be caused by network instability - we recommend refreshing your browser and trying again.',
    ],
  },
  404: {
    explination: 'page not found',
    resolution: [
      'This may have been caused by a old external link / bookmark. ',
      'If you used one of these, try to get to this page by navigating through the application instead.',
      <br />,
      'Otherwise, a internal link may have been added before the page was implimented. We will get on that!',
    ],
  },
  'insufficient-trust': {
    customView: AccessPrompt,
  },
  unknown: {
    explination: 'cause unknown',
    resolution: 'The cause of this error is not yet documented.',
  },
};

const ErrorPage = () => {
  const history = useHistory();
  const parentMatch = useRouteMatch();
  const routeMatch = useRouteMatch(`${parentMatch.path}/:errorName`);
  const errorName = routeMatch?.params?.errorName;
  const { explination, resolution, customView: CustomView } = errorStrings[errorName] || errorStrings.unknown;

  const [blamed, setBlame] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  let teamMembers;
  try {
    teamMembers = JSON.parse(getFromEnv('REACT_APP_FE_TEAM_MEMBERS'));
  } catch (error) {
    teamMembers = [];
  }

  const blameHandler = (name) => {
    setBlame(name);
    setConfirmModalOpen(true);
  };

  if (CustomView) return <CustomView />;

  return (
    <Wrapper>
      <ErrorHead>Something went wrong</ErrorHead>
      <ErrorText>
        {`A critical error has occured (${explination}), and normal function of this page cannot continue.`}
        <br />
        {resolution}
        <br />
        <br />
        {'Our developers have been notified. '}
        {!!teamMembers.length && 'Choose which one you personally blame.'}
      </ErrorText>
      <TeamContainer>
        {teamMembers.map((name) => {
          let portrait;
          try {
            // eslint-disable-next-line global-require, import/no-dynamic-require
            portrait = require(`./portraits/${name.toLowerCase()}.jpg`);
          } catch (error) {
            portrait = fallbackPortrait;
          }
          return (
            <MemberContainer key={name}>
              <MemberPortrait src={portrait} />
              <MemberName>{name}</MemberName>
              {!blamed && <Button text="Blame" isSecondary onClick={() => blameHandler(name)} /> }
            </MemberContainer>
          );
        })}
      </TeamContainer>
      <Modal
        title="Successfully blamed"
        isOpen={confirmModalOpen}
        onModalClose={() => setConfirmModalOpen(false)}
        removeMarginBottom
        footer={(
          <>
            <Button text="Close" isSecondary onClick={() => setConfirmModalOpen(false)} />
            <Button text="Go to previous page" onClick={() => history.goBack()} />
          </>
        )}
      >
        <ContentContainer>
          <p>{`This unfortunate situation has been blamed on ${blamed}. We'll make sure HR hears of this.`}</p>
        </ContentContainer>
      </Modal>
    </Wrapper>
  );
};

export default ErrorPage;
