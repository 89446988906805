import styled from 'styled-components';

export const RootDiv = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;
`;

export const BaseContainerDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 60px);
`;

export const Page = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  box-sizing: border-box;
`;
