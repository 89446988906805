import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { checkAccess, userManager, useQuery } from '../../../utils';

const delay = 1500;

const Login = () => {
  const history = useHistory();
  const query = useQuery();
  const user = useSelector((state) => state.oidc.user);

  const next = query.get('next');

  useEffect(() => {
    if (user && !user.expired) {
      // User is already logged in? what are we doing here?
      history.push('/error/404');
      return undefined;
    }

    // Ensure our login process can set cookies and storages
    if (!checkAccess()) {
      history.push('/error/insufficient-trust');
      return undefined;
    }

    // HACK FOR https://globaltranz.atlassian.net/browse/TL-2650
    // IDENTITY SERVER DOESN'T CURRENTLY HANDLE REDIRECT URLS CORRECTLY, SO HERE WE HANDLE THEM OURSELVES
    if (next) {
      sessionStorage.setItem('linkedUrl', decodeURIComponent(next));
    } else if (sessionStorage.getItem('linkedUrl')) {
      sessionStorage.removeItem('linkedUrl');
    } else {
      // this space is intentionally blank
    }
    // /HACK

    const loginTimeout = setTimeout(() => {
      userManager.signinRedirect();
    }, delay);

    return () => clearTimeout(loginTimeout);
  }, [history, user, next]);

  return (
    <div>
      <h3>Welcome to GTZ TMS</h3>
      <p>Redirecting you to the login page...</p>
    </div>
  );
};

export default Login;
