import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userFound } from 'redux-oidc';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import { Message, toast, Toast } from 'ui-components';
import SideNav from '../side-nav/side-nav';
import Header from '../top-nav';
import ErrorPage from '../error-page';
import SearchPopup from '../search-popup/search-popup';
import UserManagement from '../user-management';
import OidcCallbackHandler from './oidc-callback-handler';
import { RootDiv, BaseContainerDiv, Page } from './app.style';
import { logger } from '../../utils';
import MicroFrontend from './microfrontend';
import ServiceWorkerClient from './service-worker-client/service-worker-client';
import NewVersionMessageBody from './new-version-message-body/new-version-message-body';

logger.silly('Intializing Base App Module');
const versionCheckToastId = 'version-check-toast-id';
const {
  REACT_APP_ENV: environment,
  REACT_APP_API_TOKEN: localToken,
  REACT_APP_OIDC_AUTHORITY: authority,
  REACT_APP_MICROFRONTENDS: microfrontendConfigs,
} = process.env;

const microfrontends = JSON.parse(microfrontendConfigs).filter(
  ({ flagToEnv, route }) => (!flagToEnv || flagToEnv === environment) && route,
).map(
  ({ route, host, name }) => (
    <Route key={name} path={route}>
      <MicroFrontend name={name} host={host} />
    </Route>
  ),
);

const timeUnits = {
  second: 60,
  hour: 60, // 60 mins which is 1 hour
  oneDay: 24, // 1 day which 24 hours
};

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentLocation = useLocation();
  // Blacklist for the login redirect
  const inIgnoredRoute = useRouteMatch(['/callback', '/user/login', '/user/logout', '/error']);

  const user = useSelector((state) => state.oidc.user);
  const isSearchModalOpen = useSelector((store) => store.globalSearch.isSearchModalOpen);
  const isLoadingUser = useSelector((state) => state.oidc.isLoadingUser);

  const isLoggedIn = user && !user.expired;

  // Automatically direct the user to the login page
  useEffect(() => {
    if (isLoadingUser || isLoggedIn || inIgnoredRoute) {
      return;
    }

    if (environment === 'development' && authority === 'fake') {
      dispatch(userFound({
        access_token: localToken,
        profile: {
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name': 'SmoketestDOQA',
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress': 'test@test.com',
        },
        expires_in: timeUnits.second * timeUnits.hour * timeUnits.oneDay,
      }));
      return;
    }

    const returnToLocation = `${window.location.pathname}${window.location.search}`;
    history.push({
      pathname: '/user/login',
      search: returnToLocation !== '/' ? `?next=${encodeURIComponent(returnToLocation)}` : undefined,
    });
  }, [dispatch, history, isLoadingUser, isLoggedIn, inIgnoredRoute, currentLocation]);

  const onNewVersionDetected = useCallback(() => {
    const onRefresh = (event) => {
      event.preventDefault();
      window.location.reload();
    };
    toast(<Message
      type="info"
      width="500px"
      message={{
        body: (
          <NewVersionMessageBody id="version-check" onRefresh={onRefresh} />
        ),
      }}
    />, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: false,
      toastId: versionCheckToastId,
    });
  }, []);

  return (
    <RootDiv>
      <Header />
      <BaseContainerDiv>
        {user && <SideNav />}
        <Page>
          <Switch>
            {isLoggedIn && [
              <Redirect exact from="/" to="/sales-order-board/ltl" />,
              ...microfrontends,
            ]}
            <Route path="/error" component={ErrorPage} />
            <Route path="/callback" component={OidcCallbackHandler} />
            <Route path="/user" component={UserManagement} />
            {isLoggedIn && <Redirect from="*" to="/error/404" />}
          </Switch>
        </Page>
      </BaseContainerDiv>
      {isSearchModalOpen ? <SearchPopup /> : null}
      <Toast id="SLD-7115" />
      <ServiceWorkerClient onNewVersionDetected={onNewVersionDetected} />
    </RootDiv>
  );
}

export default App;
