import axios from 'axios';

function formatErrorResponse(error) {
  const customError = error;
  customError.status = error.response ? error.response.status : null;

  return customError;
}

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export default async function api(options) {
  const { headers = defaultHeaders, authToken, ...otherOptions } = options;
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }
  try {
    return await axios({ headers, ...otherOptions });
  } catch (error) {
    throw formatErrorResponse(error);
  }
}

api.get = (options) => api({ ...options, method: 'GET' });

api.post = (options) => api({ ...options, method: 'POST' });

api.put = (options) => api({ ...options, method: 'PUT' });

api.delete = (options) => api({ ...options, method: 'DELETE' });
