import styled from 'styled-components';
import { Text } from 'ui-components';

export const Root = styled.div`
  display: block;
`;

export const Prompt = styled(Text).attrs({ type: 'subtitle3' })`
  display: block;
`;

export const TextButton = styled.span`
  color: ${({ theme }) => theme.palette.primary.dark};
  text-decoration: underline;
  margin-top: 8px;
  display: block;
`;
