import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, useHistory } from 'react-router-dom';
import useMicroFrontend from 'gtz.web.lib.microfrontendhook';
import { Spinner } from 'ui-component-library';
import theme from 'ui-components/theme';

import logger from '../../../utils/logger';
import store from '../../../store';
import { MicroFrontendMountPoint } from './microfrontend.style';

const actions = {};

const MicroFrontend = ({ name, host }) => {
  const history = useHistory();
  const parentRouteMatch = useRouteMatch();
  const errorCallback = useCallback(() => {
    history.push('/error/fail-to-mount');
  }, [history]);

  const [containerRef, microfrontendReady] = useMicroFrontend({
    name, host, errorCallback, history, parentRouteMatch, store, actions, logger, theme,
  });

  return microfrontendReady ? <MicroFrontendMountPoint ref={containerRef} /> : <Spinner overlay />;
};

MicroFrontend.propTypes = {
  name: PropTypes.string.isRequired,
  host: PropTypes.string.isRequired,
};

export default MicroFrontend;
