import sliceFactory, { roles } from 'gtz.web.lib.storeutils/tmpl/access';

const permissions = {
  trackAndTrace: [roles.internal],
  userManagement: [roles.admin],
};

const slice = sliceFactory(permissions);

export default slice.reducer;
