import React from 'react';
import PropTypes from 'prop-types';
import { StyledModal } from './alert.styles';

const Alert = ({
  isOpen,
  title,
  onModalClose,
  onModalConfirm,
  id,
}) => (
  <StyledModal
    id={id}
    isOpen={isOpen}
    onClose={onModalClose}
    title={title}
    buttonConfig={[
      {
        text: 'Cancel',
        id: 'cancelAbandonCreateQuote',
        onClick: onModalClose,
        secondary: true,
      },
      {
        text: 'Confirm',
        id: 'abandonCreateQuote',
        onClick: onModalConfirm,
      }]}
  >
    You are attempting to navigate away in the process of creating an order. Are you sure?
  </StyledModal>
);

Alert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onModalConfirm: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default Alert;
