import { configureStore } from '@reduxjs/toolkit';
import { listener } from 'gtz.web.lib.storeutils/tmpl/access';
import React from 'react';
import { errorHandler } from 'gtz.web.lib.storeutils';
import { Message, toast } from 'ui-components';

import { logger } from '../utils';
import rootReducer from './rootReducer';

const onError = ({ error }) => {
  toast(<Message id="genericThunk_SLD-7115" message={{ body: error.message }} type="failure" />);
};

export default configureStore({
  reducer: rootReducer,
  middleware:
  (getDefaultMiddleware) => getDefaultMiddleware()
    .prepend(listener.middleware).prepend(errorHandler({ logger, callback: onError })),
});
