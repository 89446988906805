import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'ui-component-library';
import {
  FakeModal, Header, Wrapper, Body, UaDetectFallback,
} from './access-prompt.style';

import { checkAccess } from '../../../utils';

/* MAGIC: Useragents aren't the best thing to use for browser detection as they provide no guarantes that this browser
          is actually what is said in the useragent, as it could be a fork of a browser, or the user could have changed
          their useragent on purpose. It's probably good enough, but there's a backup list of links just in case.
          See https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent for more info */
const browsers = {
  Firefox: {
    check: (agent) => agent.match(/Firefox\/[0-9.]+/),
    helpUrl: 'https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop',
  },
  Edge: {
    // MAGIC: The edge useragnet is mispelled in some versions :))))))))))
    check: (agent) => agent.match(/Edg(e)?\/[0-9.]+/),
    helpUrl: 'https://answers.microsoft.com/en-us/edge/forum/edge_other-edge_win10/how-do-i-enable-third-party-cookies-in-microsoft/02617812-72de-473b-8ccf-025d29ec0bce',
  },
  Safari: {
    check: (agent) => agent.match(/Safari\/[0-9.]+/) && !agent.match(/Chrome\/[0-9.]+/),
    helpUrl: 'https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac',
  },
  Chrome: {
    check: (agent) => agent.match(/Chrome\/[0-9.]+/) && !(browsers.Edge.check(agent) || browsers.Safari.check(agent)),
    helpUrl: 'https://support.google.com/chrome/answer/95647',
  },
};

const AccessPrompt = () => {
  const history = useHistory();

  const browser = Object.values(browsers).find(({ check }) => check(navigator.userAgent));

  useEffect(() => {
    if (checkAccess()) {
      history.push('/');
    }
  }, [history]);

  return (
    <Wrapper>
      <FakeModal>
        <Header>Enable Cookies</Header>
        <Body>Your cookies for your browser are disabled. Please enable your browser cookies to access GTZ TMS.</Body>
        {browser
          ? (
            <Button text="Enable Cookies" onClick={() => window.open(browser.helpUrl)} />
          )
          : (
            <UaDetectFallback>
              <p>Instructions for:</p>
              <ul>
                {Object.entries(browsers).map(([name, { helpUrl }]) => (
                  <li>
                    <a href={helpUrl} target="_blank" rel="noreferrer">{name}</a>
                  </li>
                ))}
              </ul>
            </UaDetectFallback>
          )}
      </FakeModal>
    </Wrapper>
  );
};

export default AccessPrompt;
