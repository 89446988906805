import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useRouteMatch, useHistory, Switch, Route,
} from 'react-router-dom';
import { CallbackComponent, SignoutCallbackComponent, processSilentRenew } from 'redux-oidc';

import { userManager, logger } from '../../../utils';

const delay = 1500;

const OidcCallbackHandler = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const renewMatch = useRouteMatch(`${match.path}/renew`);

  const onSigninSuccess = () => {
    const storedLocation = sessionStorage.getItem('linkedUrl');
    sessionStorage.removeItem('linkedUrl');

    history.replace(storedLocation || '/');
  };
  const onSignoutSuccess = async () => {
    setTimeout(() => history.push('/'), delay);
  };
  const onFailure = (error) => {
    logger.error('Failure in OIDC flow', { error, match });
    history.push('/');
  };

  useEffect(() => {
    if (renewMatch) {
      processSilentRenew();
    }
  }, [renewMatch]);

  return (
    <Switch>
      <Route exact path={`${match.path}/login`}>
        <CallbackComponent
          userManager={userManager}
          successCallback={onSigninSuccess}
          errorCallback={onFailure}
        >
          <div>Signing in...</div>
        </CallbackComponent>
      </Route>
      <Route exact path={`${match.path}/logout`}>
        <SignoutCallbackComponent
          userManager={userManager}
          successCallback={onSignoutSuccess}
          errorCallback={onFailure}
        >
          <div>Signed out!</div>
        </SignoutCallbackComponent>
      </Route>
      <Route exact path={`${match.path}/renew`}>
        {/* This route purposefully left blank - handling done via useRouteMatch*/}
      </Route>
    </Switch>
  );
};

OidcCallbackHandler.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
};

export default OidcCallbackHandler;
