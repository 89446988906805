import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';
import { imageRender } from 'ui-component-library/helpers';
import { palette, typography } from 'ui-components/theme';

export default createGlobalStyle`
  ${normalize()}

  html {
    font-family: sans-serif;
    font-size: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  html,
  body {
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    ${typography.types.body3}
    color: ${palette.common.black};
    text-align: left;
    background-color: ${palette.common.white};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  img {
    ${imageRender}
  }
`;
