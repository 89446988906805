import styled from 'styled-components';
import { SPACE } from 'ui-component-library/spacing';
import {
  COLOR_LIGHT_GRAY, COLOR_GRAY, COLOR_WHITE, COLOR_BLUE,
} from 'ui-component-library/colors';
import {
  FONT_SIZE, FONT_PRIMARY, FONT_WEIGHT, LINE_HEIGHT,
} from 'ui-component-library/typo';

export const Wrapper = styled.div`
  /* HACK: Fullbleed by counteracting padding from app/app.styles.js:Page */
  margin: -${SPACE.S20} -${SPACE.S20} 0;
  width: calc(100% + (${SPACE.S20} * 2));

  background-color: ${COLOR_LIGHT_GRAY.opacity100};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FakeModal = styled.div`
  width: 362px;
  padding-bottom: ${SPACE.S30};
  background: ${COLOR_WHITE};
  box-shadow: 0px 2px 6px ${COLOR_GRAY.opacity50};
  border-radius: 10px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.h3`
  box-sizing: border-box;
  width: 100%;
  background-color: ${COLOR_BLUE.opacity100};
  padding: ${SPACE.S10} ${SPACE.S30};
  margin: unset;

  font-family: ${FONT_PRIMARY};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  font-size: ${FONT_SIZE.H3};
  line-height: ${LINE_HEIGHT.M};
  color: ${COLOR_WHITE};
`;

export const Body = styled.p`
  width: 250px;
  margin-bottom: ${SPACE.S30};
  font-family: ${FONT_PRIMARY};
  font-size: ${FONT_SIZE.S};
  line-height: ${LINE_HEIGHT.S};
  text-align: center;
`;

export const UaDetectFallback = styled(Body)`
  margin: unset;
  ul {
    margin: unset;
    padding-inline-start: unset;
    li {
      text-align: left;
    }
  }
`;
