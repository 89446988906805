import { func, string } from 'prop-types';
import React from 'react';
import { Root, Prompt, TextButton } from './new-version-message-body.style';

const NewVersionMessageBody = ({ id, onRefresh }) => (
  <Root>
    <Prompt id={`${id}_prompt`}>
      A newer version of the application is available.
    </Prompt>
    <TextButton
      id={`${id}_refresh`}
      text="Refresh"
      onClick={onRefresh}
      isPrimary
    >
      REFRESH
    </TextButton>
  </Root>
);

NewVersionMessageBody.propTypes = {
  id: string.isRequired,
  onRefresh: func.isRequired,
};

export default NewVersionMessageBody;
