import React from 'react';
import { useSelector } from 'react-redux';

import {
  TopNavContainer, LeftNavDiv, RightNavDiv, Logo, LogoIcon,
} from './top-nav.style';
import gtzLogo from './gtzLogo.svg';
import SearchBlock from './search-block/search-block';
import UserActions from './user-actions';

const cdnUrl = process.env.REACT_APP_CDN_URL;
const gtzLogoIcon = `${cdnUrl}/assets/carriers/GlobalTranz_G_FullColor.svg`;

const TopNav = () => {
  const user = useSelector((store) => store.oidc.user);
  return (
    <TopNavContainer>
      <LeftNavDiv>
        <LogoIcon src={gtzLogoIcon} />
        <Logo src={gtzLogo} />
      </LeftNavDiv>

      <RightNavDiv>
        {user && (
          <>
            <SearchBlock />
            <UserActions />
          </>
        )}
      </RightNavDiv>
    </TopNavContainer>
  );
};

export default TopNav;
