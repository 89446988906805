import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconFont, Tooltip } from 'ui-components';
import { COLOR_WHITE } from 'ui-component-library/colors';
import {
  SearchBlockWrapper,
  Form,
  StyledTextField,
  InfoTooltipContent,
} from './search-block.style';
import getResultOfGlobalSearch from '../../../store/global-search/thunkActions';

const SearchTooltipContent = () => (
  <InfoTooltipContent>
    <p>
      Enter one or more of the following details to perform a search. Please
      separate search terms by a comma.
    </p>
    <ul>
      <li>BOL# or PRO# or REF# </li>
      <li>Customer</li>
      <li>Customer PO#</li>
      <li>Shipper Name, City, State or ZIP</li>
      <li>Consignee Name, City, State or ZIP</li>
      <li>Sales Rep</li>
      <li>Mode (LTL or TL)</li>
      <li>Equipment Type (for TL only)</li>
      <li>Booked By (for TL only)</li>
      <li>Customer BOL</li>
    </ul>
  </InfoTooltipContent>
);

const SearchBlock = () => {
  const [searchString, setSearchString] = useState('');
  const dispatch = useDispatch();

  const searchHandler = (event) => {
    event.preventDefault();
    dispatch(getResultOfGlobalSearch(searchString));
  };

  return (
    <SearchBlockWrapper>
      <Form onSubmit={searchHandler}>
        <StyledTextField
          autoComplete="off"
          id="topNav_TL-2970_search_1"
          type="text"
          icon="Search"
          iconColor={COLOR_WHITE}
          textColor={COLOR_WHITE}
          iconSize={20}
          value={searchString}
          onValueChange={(event) => {
            setSearchString(event.target.value);
          }}
          onIconClick={searchHandler}
          tabIndex={-1}
        />
      </Form>
      <Tooltip
        distance={10}
        fallbackPlacements={['auto']}
        id="transaction-search-tooltip"
        placement="bottom"
        showArrow
        showOnHover
        skidding={0}
        title="Transaction Search"
        tooltipContent={<SearchTooltipContent />}
      >
        <IconFont
          className="tooltip-toggle"
          icon="CircleQuestion"
          id="transaction-search-tooltip-toggle"
          size="20px"
          type="default"
        />
      </Tooltip>
    </SearchBlockWrapper>
  );
};

export default SearchBlock;
