import styled, { css } from 'styled-components';
import {
  COLOR_DARK_GRAY,
  COLOR_LIGHT_GRAY,
  COLOR_DARK_BLUE,
  COLOR_LIGHT_BLUE,
  COLOR_WHITE,
} from 'ui-component-library/colors';
import { SPACE } from 'ui-component-library/spacing';
import { FONT_SIZE, FONT_WEIGHT } from 'ui-component-library/typo';

export const Root = styled.div`
  position: relative;
  overflow: auto;
  flex: 1;
  display: flex;
  justify-content: space-around;
  height: auto;
`;

export const TableWrapper = styled.div`
  overflow: scroll;
  display: flex;
  flex-flow: row wrap;
  flex-direction: column;
  height: auto;
  & > div {
    display: flex;
    flex-wrap: wrap;
    ${(props) => props.width
      && css`
        width: ${props.width};
      `}
  }
`;

export const TableItem = styled.div`
  padding: 8px;
  text-align: left;
  width: 80px;
  flex-shrink: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${(props) => props.width
    && css`
      width: ${props.width}px;
    `}
  ${(props) => props.highlight
    && css`
      background-color: #f5aa2b;
      color: ${COLOR_WHITE};
    `}
  &:last-child {
    margin-right: 0;
  }
`;

export const TableRow = styled.div`
  width: 100%;
  background-color: ${COLOR_WHITE};
  &:last-child {
    border: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const TableHeader = styled.div`
  position: sticky;
  top: 0;
  color: ${COLOR_DARK_BLUE.opacity100};
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  font-size: ${FONT_SIZE.PARAGRAPH};
  & ${TableRow} {
    position: sticky;
    top: 0;
    padding: 0 ${SPACE.S15} ${SPACE.S5} ${SPACE.S20};
    align-items: baseline;
    display: flex;
  }
`;

export const RowInfo = styled.div`
  align-items: stretch;
  display: flex;
  padding: 0 ${SPACE.S15} 0 ${SPACE.S20};
  width: 100%;
  ${(props) => props.isActive
    && css`
      background-color: ${COLOR_LIGHT_BLUE.opacity50};
    `}
`;

export const TableBody = styled.div`
  font-size: ${FONT_SIZE.BASE};
  color: ${COLOR_DARK_GRAY.opacity100};
  max-height: 500px;
  & ${TableRow} {
    &:nth-child(odd) {
      & ${RowInfo} {
        background-color: ${COLOR_LIGHT_GRAY.opacity25};
      }
    }
  }
`;

export const MessageDiv = styled.div`
  font-size: ${FONT_SIZE.H3};
  margin: ${SPACE.S12} 0 0 ${SPACE.S20};
`;
