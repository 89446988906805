const logLib = require('gtz.web.lib.logger');

const {
  NODE_ENV: environment,
} = process.env;

let logConfig = JSON.parse(process.env.REACT_APP_LOGGER_CONFIG || '{}');

if (!logConfig.transports) {
  logConfig = { transports: [] };

  if (environment !== 'production') {
    logConfig.transports.push({
      name: 'Console',
      config: {
        format: 'console',
        level: 'silly',
      },
    });
  }
}

logLib.init(logConfig);
const { logger } = logLib;

export default logger;
