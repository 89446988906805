import styled from 'styled-components';
import { COLOR_DARK_GRAY } from 'ui-component-library/colors';
import { FONT_SIZE } from 'ui-component-library/typo';

export const CardBlock = styled.div`
  max-width: 90vw;
  width: 1520px;
  min-height: 600px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`;

export const SearchResultHeader = styled.h4`
  font-size: ${FONT_SIZE.M};
  color: ${COLOR_DARK_GRAY.opacity100};
  margin-top: 35px;
  padding: 0 30px;
  margin-bottom: 30px;
  margin-right: 0;
`;

export const SpinnerBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ModalTitle = styled.div`
  padding:  0 10px ;
`;
