import { combineReducers } from 'redux';

import oidcReducer from './oidc/slice';
import globalSearchReducer from './global-search/slice';
import accessReducer from './access/slice';

const rootReducer = combineReducers({
  oidc: oidcReducer,
  globalSearch: globalSearchReducer,
  access: accessReducer,
});

export default rootReducer;
