import styled from 'styled-components';
import { Modal, Text } from 'ui-components';

import { StyledNavButton } from '../../shared-components/nav-button/nav-button.style';

export const LeftBarNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 70px;
  width: 64px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.primary.main};

  a {
    text-decoration: none;

    &.active {
      cursor: default;

      ${StyledNavButton} {
        background-color: ${({ theme }) => theme.palette.primary.dark};
        cursor: default;
        .nav-button-icon .icon-svg {
          cursor: default;
          fill: ${({ theme }) => theme.palette.common.white};
        }
        .nav-button-text {
          color: ${({ theme }) => theme.palette.common.white};
        }
      }

    }
  }
`;

export const StyledModal = styled(Modal)`
  .modal-body {
    div:last-child {
      justify-content: flex-end;
    }
  }
`;

export const Title = styled(Text).attrs({ type: 'body1' })`
  margin-bottom: 5px;
`;

export const StyledLink = styled(Text).attrs({ type: 'body2' })`
  && {
      color: ${({ theme }) => theme.palette.primary.main};
      text-decoration: underline;
      margin-top: 13px;
      cursor: pointer;
  }
  &:hover {
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;
