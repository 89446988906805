import assert from 'assert';

export default function checkAccess() {
  // Check webstorage access
  try {
    window.localStorage.setItem('__test', 'data');
    assert(window.localStorage.getItem('__test') === 'data');
    window.localStorage.removeItem('__test');

    window.sessionStorage.setItem('__test', 'data');
    assert(window.sessionStorage.getItem('__test') === 'data');
    window.sessionStorage.removeItem('__test');
  } catch (error) { // DOMException on access error, or AssertionError from assert failures
    return false;
  }

  // Test if cookies are unsupported (old browser?)
  if (!navigator.cookieEnabled) return false;

  // Set a test cookie
  document.cookie = 'cookietest=1';
  const hasTestCookie = document.cookie.indexOf('cookietest=') !== -1;
  // MAGIC: Delete cookie by setting its expiration date to the UNIX Epoch
  document.cookie = `cookietest=1; expires=${(new Date(0)).toGMTString()}`;

  return hasTestCookie;
}
