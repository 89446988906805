import styled, { css } from 'styled-components';
import {
  Text,
} from 'ui-components';

export const StyledText = styled(Text).attrs({ type: 'caption' })`
  color: ${({ theme }) => theme.palette.primary.lighter};  
`;

export const StyledNavButton = styled.button`
  padding: 1px;
  height: 64px;
  width: 64px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  transition: 0.3s;
  .nav-button-icon .icon-svg {
    fill: ${({ theme }) => theme.palette.primary.lighter};
  }
  & {
    :hover {
      background-color: ${({ theme }) => theme.palette.primary.light};
    }
    
    ${(props) => props.isActive
    && css`
        &, &:hover {
          background-color: ${({ theme }) => theme.palette.primary.dark};
          ${StyledText} {
            color: ${({ theme }) => theme.palette.common.white};
          }
          .nav-button-icon {
            .icon-container .icon-svg {
              fill: ${({ theme }) => theme.palette.common.white};
            }
          }
        }
    `}
  }
`;
