import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isSearchModalOpen: false,
  orders: [],
  loading: false,
  failed: false,
  searchQuery: '',
  error: null,
};

const globalSearchSlice = createSlice({
  initialState,
  name: 'globalSearch',
  reducers: {
    setResultOfGlobalSearch(state, { payload }) {
      state.loading = false;
      state.orders = payload;
    },
    startSearching(state, { payload }) {
      state.isSearchModalOpen = true;
      state.searchQuery = payload;
      state.loading = true;
      state.orders = [];
    },
    searchFailed(state, { payload }) {
      state.isSearchModalOpen = false;
      state.searchQuery = payload.searchText;
      state.loading = false;
      state.failed = true;
      state.error = {
        status: payload.status,
        message: payload.message,
      };
    },
    resetState() { return initialState; },
  },
});

export const {
  setResultOfGlobalSearch,
  closeSearchPopUp,
  startSearching,
  searchFailed,
  resetState,
} = globalSearchSlice.actions;

export default globalSearchSlice.reducer;
