import styled from 'styled-components';

const MicroFrontendMountPoint = styled.div`
  display: flex;
  width: fit-content;
  min-width: 100%;
  min-height: 100%;
`;

export { MicroFrontendMountPoint };
