import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconFont, RequireModal, Text } from 'ui-components';

import {
  UserButton,
  CurrentUserLabel,
  CurrentUserValue,
  UserPopover,
  UserPopoverRow,
  LogoutButton,
  LogOutText,
  Splitter,
} from './user-actions.styles';

const id = 'user-actions';

const UserActions = () => {
  const history = useHistory();
  const popoverRef = useRef();
  const user = useSelector((store) => store.oidc.user);

  const [isUserPopoverOpen, setIsUserPopoverOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const handleConfirmLogout = (event) => {
    event.stopPropagation();
    setIsConfirmModalOpen(false);
    history.push('/user/logout');
  };

  const handleCancelLogout = () => {
    setIsConfirmModalOpen(false);
  };

  const handleOpenUserPopover = (event) => {
    event.stopPropagation();
    setIsUserPopoverOpen((isOpen) => !isOpen);
  };

  const handleOpenConfirmModal = () => {
    setIsUserPopoverOpen(false);
    setIsConfirmModalOpen(true);
  };

  const logoutModalButtonConfig = [
    {
      id: `${id}-cancel-button`,
      text: 'CANCEL',
      secondary: true,
      onClick: handleCancelLogout,
    },
    {
      id: `${id}-confirm-button`,
      text: 'CONFIRM',
      onClick: handleConfirmLogout,
    },
  ];

  useEffect(() => {
    if (isUserPopoverOpen) {
      const outsideClickListener = (event) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
          setIsUserPopoverOpen(false);
        }
      };
      document.addEventListener('click', outsideClickListener);
      return () => document.removeEventListener('click', outsideClickListener);
    }
    return undefined;
  }, [isUserPopoverOpen]);

  return (
    <>
      <UserButton isActive={isUserPopoverOpen} onClick={handleOpenUserPopover}>
        <IconFont
          size="24px"
          type="default"
          icon="Profile"
          id={id}
        />
      </UserButton>
      {isUserPopoverOpen && (
        <UserPopover ref={popoverRef}>
          <UserPopoverRow>
            <CurrentUserLabel>
              Current User:
            </CurrentUserLabel>
            <CurrentUserValue>
              {user.username}
            </CurrentUserValue>
          </UserPopoverRow>
          <Splitter />
          <UserPopoverRow>
            <LogoutButton onClick={handleOpenConfirmModal}>
              <LogOutText>LOGOUT</LogOutText>
            </LogoutButton>
          </UserPopoverRow>
        </UserPopover>
      )}

      <RequireModal
        buttonConfig={logoutModalButtonConfig}
        isOpen={isConfirmModalOpen}
        title="Logout"
        onClose={handleCancelLogout}
      >
        <Text type="body1">
          Are you sure you want to Logout from the system?
        </Text>
      </RequireModal>
    </>
  );
};

export default UserActions;
