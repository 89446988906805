import styled from 'styled-components';
import { Text } from 'ui-components';
import { shadows } from 'ui-components/theme';

export const UserButton = styled.div.attrs({ role: 'button', zIndex: 0 })`
  cursor: pointer;
  padding: 20px;

  &:hover {
    ${({ isActive }) => (isActive ? '' : 'background-color: rgba(255, 255, 255, 0.2);')}
  }

  ${({ isActive, theme }) => (isActive ? `background-color: ${theme.palette.primary.dark};` : '')}
`;

export const UserPopover = styled.div`
  position: absolute;
  top: 64px;
  right: 0px;
  z-index: 10;
  width: 320px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: ${shadows.shadow1};
  border-radius: 0px 0px 8px 8px;
`;

export const UserPopoverRow = styled.div`
  padding: 16px;

  &:not(:last-child) {
    padding-bottom: 8px;
  }

  &:not(:first-child) {
    padding-top: 8px;
  }
`;

export const CurrentUserLabel = styled(Text).attrs({ type: 'subtitle1' })`
  color: ${({ theme }) => theme.palette.primary.dark};
  margin-bottom: 4px;
`;

export const CurrentUserValue = styled(Text).attrs({ type: 'body2' })`
  color: ${({ theme }) => theme.palette.common.black};
`;

export const Splitter = styled.div.attrs({ role: 'separator' })`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.gray.lighter};
`;

export const LogoutButton = styled.div.attrs({ role: 'button', zIndex: 0 })`
  cursor: pointer;
`;

export const LogOutText = styled(Text).attrs({ type: 'subtitle2' })`
  color: ${({ theme }) => theme.palette.primary.dark};
`;
