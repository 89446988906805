import React, { useEffect, useState } from 'react';
import { userManager, logger } from '../../../utils';

const {
  REACT_APP_ENV: environment,
  REACT_APP_OIDC_AUTHORITY: authority,
} = process.env;

const timeoutDuration = 5000;

const logout = () => {
  if (environment === 'development' && authority === 'fake') {
    window.location = '/callback/logout';
  } else {
    userManager.signoutRedirect();
  }
};

const resetSw = async () => {
  const registrations = await navigator.serviceWorker.getRegistrations();
  registrations.forEach((sw) => sw.unregister());

  // Blindly delete all caches
  const cacheKeys = await caches.keys();
  await Promise.all(cacheKeys.map((key) => caches.delete(key)));

  logout();
};

const handleSw = async () => {
  const registration = await navigator?.serviceWorker?.ready;
  registration.active.postMessage({ type: 'INVALIDATE_ALL' });
};

const Logout = () => {
  const [cacheCleared, setCacheCleared] = useState(false);

  useEffect(() => {
    if (navigator?.serviceWorker?.controller) {
      const channel = new BroadcastChannel('sw-messages');
      const handleSetCacheCleared = (event) => {
        if (event.data.type === 'ALL_CACHE_CLEAR_COMPLETED') {
          setCacheCleared(true);
        }
      };
      channel.addEventListener('message', handleSetCacheCleared);

      const swWatchdog = setTimeout(() => {
        logger.error('Reached timeout waiting for cache clear', { timeoutDuration });
        resetSw();
      }, timeoutDuration);

      handleSw();

      return () => {
        channel.removeEventListener('message', handleSetCacheCleared);
        clearTimeout(swWatchdog);
      };
    }

    setCacheCleared(true);
    return undefined;
  }, []);

  useEffect(() => {
    if (cacheCleared) {
      logout();
    }
  }, [cacheCleared]);

  return (
    <div>Signing out...</div>
  );
};

export default Logout;
