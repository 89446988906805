import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import NavButton from '../../../shared-components/nav-button';

const noop = () => {
  // This is intentional
};

const IconLink = ({
  to, title, onClick, icon, target, tabIndex, text, id,
}) => (
  <NavLink
    to={to}
    title={title}
    onClick={(e) => onClick(e, to)}
    target={target}
    tabIndex={tabIndex}
  >
    <NavButton
      id={id}
      onClick={noop}
      icon={icon}
      text={text}
      tabIndex={tabIndex}
    />
  </NavLink>
);

IconLink.propTypes = {
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  target: PropTypes.string,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      state: PropTypes.shape({}),
    }),
  ]).isRequired,
};

IconLink.defaultProps = {
  onClick: noop,
  tabIndex: undefined,
  target: undefined,
};

export default IconLink;
