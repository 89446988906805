import styled from 'styled-components';
import { TextField } from 'ui-component-library';
import { COLOR_WHITE } from 'ui-component-library/colors';

export const StyledTextField = styled(TextField)`
  && {
    border-bottom: 1px solid ${COLOR_WHITE};
  }
`;

export const Form = styled.form`
  margin-right: 12px;
`;

export const SearchBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;

  .eDghrs {
    border-bottom: 1px solid ${COLOR_WHITE};
    width: 200px;
    padding-top: 0;
  }

  .popper-content-wrapper {
    padding: 8px 12px;
  }
`;

export const InfoTooltipContent = styled.div`
  width: 220px;
  margin-top: 3px;
  color: ${({ theme }) => theme.palette.common.black};

  > ul {
    padding: 0;
    margin: 4px 0 0 0;
    li {
      white-space: nowrap;
      list-style-type: none;
      margin-bottom: 2px;
    }
    li:before {
      content: '';
      display: inline-block;
      height: 2px;
      width: 2px;
      vertical-align: middle;
      border-radius: 50%;
      background-color: #000;
      margin-right: 5px;
    }
  }
  > p {
    margin: 0;
  }
`;
