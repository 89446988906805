import { logger } from './utils';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === '[::1]'
    // 127.0.0.0/8 are considered localhost for IPv4.
    || window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

async function registerValidSW(url) {
  try {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      throw Error('PUBLIC_URL environment variable does not match actual URL');
    }

    await navigator.serviceWorker.register(url);
  } catch (error) {
    logger.error('Failed to install serviceWorker', { error });
  }
}

export async function unregister() {
  const registration = await navigator.serviceWorker.ready;
  registration.unregister();
}

const swUrl = '/service-worker.js';

export async function register() {
  await registerValidSW(swUrl);
  if (isLocalhost) {
    logger.info('This web app is being served cache-first by a service worker. To learn more, visit https://bit.ly/CRA-PWA');
  }
}
