import styled from 'styled-components';
import { FONT_SIZE, FONT_WEIGHT } from 'ui-component-library/typo';
import { SPACE } from 'ui-component-library/spacing';

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 20px 20px;
`;
export const ErrorHead = styled.h1`
  font-size: ${FONT_SIZE.H1};
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
`;

export const ErrorText = styled.p`
  font-size: ${FONT_SIZE.S};
`;

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 24px;
  margin: 16px ${SPACE.S8};
`;

export const MemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 15%;
`;

export const MemberName = styled.p`
  font-size: ${FONT_SIZE.M};
  margin: ${SPACE.S8} 0 ${SPACE.S12};
`;

export const MemberPortrait = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
`;

export const ContentContainer = styled.div`
  width: 560px;
  & p {
    margin-bottom: 0;
  }
`;
